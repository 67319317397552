import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import StorybookEmbed from "../../../components/StorybookEmbed.jsx";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl="#" codeUrl="#" checklists={checklists.componentRating.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`A star rating component is a user interface element that allows users to provide feedback or rate items by selecting a number of stars, typically ranging from one to five.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
     `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/rating/android-compose-rating.gif",
            "align": "center",
            "resize-mode": "contain"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Variants`}</h2>
    <p>{`Legion has 3 variants for Rating :`}</p>
    <ol>
      <li parentName="ol">{`Empty Rating`}</li>
      <li parentName="ol">{`Half Rating`}</li>
      <li parentName="ol">{`Full Rating`}</li>
    </ol>
    <h2>{`Usage`}</h2>
    <p>{`The following is code to implement Rating component.`}</p>
    <h3>{`Rating`}</h3>
    <h4>{`Empty Rating`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`val rating = remember { mutableStateOf(0.0) }
LgnRatingBar(
    rating = rating.value,
    totalCount = 5
) {
    rating.value = it
}
`}</code></pre>
    <h4>{`Half Rating`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`val rating = remember { mutableStateOf(2.5) }
LgnRatingBar(
    rating = rating.value,
    totalCount = 5
) {
   rating.value = it
}
`}</code></pre>
    <h4>{`Full Rating`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`val rating = remember { mutableStateOf(5.0) }
LgnRatingBar(
    rating = rating.value,
    totalCount = 5
) {
    rating.value = it
}
`}</code></pre>
    <h3>{`State`}</h3>
    <h4>{`Normal State`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`val rating = remember { mutableStateOf(3.5) }
LgnRatingBar(
    modifier = Modifier.size(40.dp),
    rating = rating.value,
    totalCount = 5
) {
    rating.value = it
}
`}</code></pre>
    <h4>{`Disabled State`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`val rating = remember { mutableStateOf(3.5) }
LgnRatingBar(
    modifier = Modifier.size(40.dp),
    rating = rating.value,
    totalCount = 5,
    enable = false
) {
    rating.value = it
}
`}</code></pre>
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameters`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`rating`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Double`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set value of Rating`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`totalCount`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Int`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Total Count of Rating`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Color of Rating`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`enable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Enable or Disable of Rating`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`halfIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ImageVector`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Half Icon of Rating`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`fullIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ImageVector`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Full Icon of Rating`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`emptyIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ImageVector`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Empty Icon of Rating`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onRatingChange`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Function`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Action When Rating change`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      